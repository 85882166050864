<template>
  <div class="w-screen overflow-x-hidden wrapper">
    <div class="relative z-50 nav">
      <div class="logo">
        <img src="../assets/logo-l.png" alt="Nvoko Logo" />
      </div>
      <div class="nav-menu">
        <div v-if="!user" @click="showLoginModal = true" class="log-in">
          Log In
        </div>

        <template v-else>
          <a class="log-in" href="/catalog">Go to account</a>
          <button class="log-out" @click="logout">
            Logout
          </button>
        </template>

        <!-- <div @click="showSignupModal = true" class="sign-up">
          Get Started
        </div> -->
      </div>
    </div>

    <div
      class="flex items-center justify-center w-full "
      style="height: calc(100vh - 114px);"
    >
      <div class="w-full h-full">
        <NvokoLogo3d :width="mockWidth" />
      </div>
      <div
        class="container absolute inset-0 items-center justify-center w-full h-full mx-auto hero"
      >
        <div ref="3dMock" class="h-full col-span-4 md:col-span-2 "></div>
        <div class="col-span-4 md:col-span-2 right">
          <div class="title">
            <animated-text
              textToAnim="Modern music contracts."
              wordsOnly
              :scaleFrom="1.02"
              :rotation="0"
              :wordsStagger="0.15"
              :delay="0.2"
            />
          </div>
          <div class="description">
            <animated-text
              textToAnim="Start securing your creative rights."
              wordsOnly
              :scaleFrom="1.02"
              :rotation="0"
              :wordsStagger="0.15"
              :delay="0.4"
            />
            <animated-text
              textToAnim="Build your deal, negotiate, and sign."
              wordsOnly
              :scaleFrom="1.02"
              :rotation="0"
              :wordsStagger="0.15"
              :delay="0.6"
            />
          </div>
          <template v-if="!user">
            <div v-if="!success" class="register">
              <input
                class="email-input"
                v-model="email"
                placeholder="Your email address"
                @input="
                  () => {
                    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (this.email && emailRegex.test(this.email))
                      this.invalidEmail = false;
                  }
                "
              />
              <button :disabled="loading" @click="preRegister" class="sign-up">
                <template v-if="loading">
                  loading...
                </template>
                <template v-else>
                  Get a free contract
                </template>
              </button>
            </div>
            <div v-else class="register-success">
              <p class="description-light">
                Thank you for signing up! We’re currently in beta, and will send
                out invites as space opens up.
              </p>
            </div>
            <div v-if="takenEmail" class="error">
              <p>
                You're already on our waitlist! We’re currently in beta, and
                will send out invites as space opens up.
              </p>
            </div>
            <div v-if="invalidEmail" class="error">
              <p>Please enter a valid email address.</p>
            </div>
          </template>
          <template v-else-if="user.is_waitlisted">
            <p class="description-light">
              Thank you for signing up! We’re currently in beta, and will send
              out invites as space opens up.
            </p>
          </template>
        </div>
      </div>
    </div>
    <div class="how-does-it-work pt-10">
      <div
        class="section-title"
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-duration="800"
      >
        How does it work?
      </div>
      <div class="steps">
        <div class="step">
          <div class="deal-info">
            <div
              class="ipi"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="800"
            >
              <div class="linked">
                <div class="icon">
                  <img src="../assets/icons/Check.svg" />
                </div>
                <div class="text">IPI LINKED</div>
              </div>
              <div class="number">00077406269</div>
            </div>
            <div
              class="summary"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="800"
            >
              <div
                class="el"
                data-sal="slide-up"
                data-sal-delay="350"
                data-sal-duration="800"
              >
                <div class="number">50%</div>
                <div class="text">Publishing</div>
              </div>
              <div
                class="el"
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-duration="800"
              >
                <div class="number">35%</div>
                <div class="text">Recording</div>
              </div>
              <div
                class="el"
                data-sal="slide-up"
                data-sal-delay="450"
                data-sal-duration="800"
              >
                <div class="number">$3600</div>
                <div class="text">Guest, Fee</div>
              </div>
            </div>
            <div class="contribution-host">
              <div
                class="contribution-container"
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-duration="800"
              >
                <div class="contribution">Mixing, Mastering</div>
                <div class="subtitle">Production Contribution</div>
              </div>
              <div
                class="party"
                data-sal="slide-up"
                data-sal-delay="800"
                data-sal-duration="800"
              >
                <div class="initials">Lu</div>
                <div class="name">Lucidious</div>
                <div class="party-tag">HOST</div>
              </div>
            </div>
            <div
              class="collaborator"
              data-sal="slide-up"
              data-sal-delay="1300"
              data-sal-duration="800"
            >
              <div class="party">
                <div class="initials">JW</div>
                <div class="name">Joel Woods</div>
                <div class="party-tag">PRODUCER</div>
              </div>
              <div class="add-collaborator">
                <button>
                  <span class="icon">
                    <img src="../assets/icons/User-solid.svg" />
                  </span>
                  <span class="text">Add Collaborator</span>
                </button>
              </div>
            </div>
          </div>
          <div class="step-number">
            <span class="number">1</span>
          </div>
          <div class="step-text">
            <div class="title">
              <animated-text
                textToAnim="Build your deal"
                wordsOnly
                :scaleFrom="1.02"
                :rotation="0"
                :wordsStagger="0.15"
                :delay="1"
              />
            </div>
            <div class="description">
              <animated-text
                textToAnim="We’ll walk you through royalties, copyrights, publishing, and more to craft your personalized contract."
                wordsOnly
                :scaleFrom="1.02"
                :rotation="0"
                :wordsStagger="0.15"
                :delay="1.4"
              />
            </div>
          </div>
        </div>
        <div class="step">
          <div class="step-text">
            <div class="title">
              <animated-text
                textToAnim="Negotiate the details"
                wordsOnly
                :scaleFrom="1.02"
                :rotation="0"
                :wordsStagger="0.15"
                :delay="1"
              />
            </div>
            <div class="description">
              <animated-text
                textToAnim="Review and discuss the details, and resolve conflict before it happens."
                wordsOnly
                :scaleFrom="1.02"
                :rotation="0"
                :wordsStagger="0.15"
                :delay="1.4"
              />
            </div>
          </div>
          <div class="step-number">
            <span class="number">2</span>
          </div>
          <div class="contract-questions">
            <div
              class="row-1"
              data-sal="slide-up"
              data-sal-delay="1300"
              data-sal-duration="800"
            >
              <div class="question">
                <div class="title">Song Credit</div>
                <div class="question-text">How will the guest be credited?</div>
                <div class="options">
                  <div class="option selected">Co-Headline</div>
                  <div class="option">Feature</div>
                </div>
              </div>
              <div class="status">
                <span class="icon">
                  <img src="../assets/icons/Bell-solid.svg" />
                </span>
                <div class="status-text">Answer updated</div>
              </div>
            </div>
            <div class="row-2">
              <div
                class="question-1"
                data-sal="slide-up"
                data-sal-delay="1300"
                data-sal-duration="800"
              >
                <div class="question">
                  <div class="title">Guest Fee recoupment</div>
                  <div class="question-text">
                    Is the $350 guest fee recoupable <br />
                    against future record royalties?
                  </div>
                  <div class="options">
                    <div class="option selected">Yes</div>
                    <div class="option">No</div>
                  </div>
                </div>
              </div>
              <div
                class="question-2"
                data-sal="slide-up"
                data-sal-delay="1300"
                data-sal-duration="800"
              >
                <div class="status">
                  <span class="icon">
                    <img src="../assets/icons/Comments.svg" />
                  </span>
                  <div class="status-text">New Comment</div>
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-delay="2300"
                  data-sal-duration="800"
                  class="question"
                >
                  <div class="title">Royalty Recoupment</div>
                  <div class="question-text">
                    After recoupment, how are royalties <br />
                    compute and paid?
                  </div>
                  <div class="options">
                    <div class="option">Retroactively</div>
                    <div class="option selected">Prospectively</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="step">
          <div class="contract-final">
            <div class="top">
              <button
                data-sal="slide-up"
                data-sal-delay="1300"
                data-sal-duration="800"
              >
                <span class="icon">
                  <img src="../assets/icons/Hand-holding-dollar.svg" />
                </span>
                <span class="text">Request Payment</span>
              </button>
              <div
                class="ip"
                data-sal="slide-up"
                data-sal-delay="1700"
                data-sal-duration="800"
              >
                <div class="recorded">
                  <span class="icon">
                    <img src="../assets/icons/Globe.svg" />
                  </span>
                  <div class="text">IP RECORDED</div>
                </div>
                <div class="user-ip">127.0.0.1</div>
              </div>
            </div>
            <div class="agreement-container">
              <div
                class="agreement"
                data-sal="slide-up"
                data-sal-delay="2300"
                data-sal-duration="1500"
              >
                <div class="container">
                  <div class="text">
                    <div class="title">Featured Artist Agreement</div>
                    <div class="content">
                      &nbsp;&nbsp;&nbsp;<b>Host Company</b> and
                      <b>Guest Company</b> enter into this Featured Artist
                      Agreement (“<b>Agreement</b>”) in connection with the
                      production services of Guest Individual being furnished by
                      Guest Company involving creation and/or production of
                      master recording(s) (the “<b>Master</b>”) featuring
                      recorded performances of a musical composition (the
                      “<b>Composition</b>”) embodying the performance of Host
                      Individual (the “<b>Project</b>”). Except as otherwise set
                      forth herein, all references to the Master hereunder shall
                      include Deliverables (defined below). The parties intend
                      that this shall be a binding contract upon its full
                      execution.
                    </div>
                  </div>
                  <div class="scroll">
                    <div class="thumb"></div>
                  </div>
                </div>
              </div>
              <div class="accept-sign">
                <button>
                  <span class="icon">
                    <img src="../assets/icons/File-signature.svg" />
                  </span>
                  <span class="text">Accept and Sign</span>
                </button>
              </div>
              <div
                class="signature-container"
                data-sal="slide-up"
                data-sal-delay="2600"
                data-sal-duration="800"
              >
                <span class="icon">
                  <img src="../assets/icons/Fingerprint.svg" />
                </span>
                <div class="signature">John Wick</div>
              </div>
            </div>
          </div>
          <div class="step-number">
            <span class="number">3</span>
          </div>
          <div class="step-text">
            <div class="title">
              <animated-text
                textToAnim="Secure your rights"
                wordsOnly
                :scaleFrom="1.02"
                :rotation="0"
                :wordsStagger="0.15"
                :delay="1.4"
              />
            </div>
            <div class="description">
              <animated-text
                textToAnim="Digitally sign and store your contract."
                wordsOnly
                :scaleFrom="1.02"
                :rotation="0"
                :wordsStagger="0.15"
                :delay="1.9"
              />

              <animated-text
                textToAnim="[coming soon] If funds are involved, they'll be held securely until the project is delivered."
                wordsOnly
                :scaleFrom="1.02"
                :rotation="0"
                :wordsStagger="0.15"
                :delay="2.4"
              />

              <br />
            </div>
          </div>
        </div>
        <div class="steps-end">
          <div class="circle"></div>
        </div>
      </div>
    </div>
    <div class="join-community" style="display: none">
      <div class="section-title">
        Join the Nvoko community
      </div>
      <div class="testimonials">
        <div class="testimonial">
          <div class="quotes">
            <img src="../assets/quotes.svg" alt="" />
          </div>
          <div class="top">
            <div class="avatar">
              <img src="https://placehold.co/105x105/blue/red" alt="" />
            </div>
            <div class="right">
              <div class="name">Dash</div>
              <div class="user-title">Creative director</div>
            </div>
          </div>
          <div class="description">
            I was blown away by the accuracy and speed of the AI chatbot on
            Brainwave. It was able to understand my queries and provide relevant
            recommendations in seconds.
          </div>
        </div>
        <div class="testimonial">
          <div class="quotes">
            <img src="../assets/quotes.svg" alt="" />
          </div>
          <div class="top">
            <div class="avatar">
              <img src="https://placehold.co/105x105/blue/red" alt="" />
            </div>
            <div class="right">
              <div class="name">Dash</div>
              <div class="user-title">Creative director</div>
            </div>
          </div>
          <div class="description">
            I was blown away by the accuracy and speed of the AI chatbot on
            Brainwave. It was able to understand my queries and provide relevant
            recommendations in seconds.
          </div>
        </div>
        <div class="testimonial">
          <div class="quotes">
            <img src="../assets/quotes.svg" alt="" />
          </div>
          <div class="top">
            <div class="avatar">
              <img src="https://placehold.co/105x105/blue/red" alt="" />
            </div>
            <div class="right">
              <div class="name">Dash</div>
              <div class="user-title">Creative director</div>
            </div>
          </div>
          <div class="description">
            I was blown away by the accuracy and speed of the AI chatbot on
            Brainwave. It was able to understand my queries and provide relevant
            recommendations in seconds.
          </div>
        </div>
      </div>
      <div class="slider-control">
        <div class="point"></div>
        <div class="point"></div>
        <div class="point"></div>
      </div>
    </div>
    <!-- <div class="get-started">
      <div class="main">
        <div class="title">Secure your rights</div>
        <div class="description">
          Unlimited contracts covering <span class="accent">buy-out</span>,
          <span class="accent">record royalties</span>, and
          <span class="accent">songwriter splits</span> — all for
          <span class="accent">$49 per song</span>.
        </div>
        <div class="action">
          <button @click="showSignupModal = true">Get a free contract</button>
        </div>
      </div>
      <div class="unlimited">
        <div class="description">
          Go unlimited for <span class="accent">$299 per year</span>, per
          artist.
        </div>
        <div class="action">
          <button>Contact us</button>
        </div>
      </div>
    </div> -->
    <Pricing />
    <div class="footer">
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="links">
        <div class="copyright">© 2024. All rights reserved. Nvoko, LLC.</div>
        <div class="link">Terms of Use</div>
        <div class="link">Privacy Policy</div>
        <div class="link">Manage Cookie Preferences / Do Not Sell</div>
        <div class="socials">
          <span class="icon">
            <img src="../assets/icons/Social-facebook-accent.svg" />
          </span>
          <span class="icon">
            <img src="../assets/icons/Social-instagram-accent.svg" />
          </span>
        </div>
      </div>
    </div>
    <LoginModal
      v-if="showLoginModal"
      @close="showLoginModal = false"
    ></LoginModal>
    <SignupModal
      v-if="showSignupModal"
      @close="
        next => {
          showSignupModal = false;
          if (next === 'login') {
            showLoginModal = true;
          }
        }
      "
      :initialEmail="email"
    ></SignupModal>
  </div>
</template>

<script>
import LoginModal from "@/components/modals/Login.vue";
import SignupModal from "@/components/modals/Signup.vue";
import Pricing from "../components/Pricing.vue";
import NvokoLogo3d from "../components/NvokoLogo3d.vue";
// import m_pre_register from "@/api/mutations/PRE_REGISTER.gql";
import AnimatedText from "@/components/AnimatedText.vue";
import sal from "sal.js";

export default {
  mounted() {
    this.mockWidth = this.$refs["3dMock"].offsetWidth;
    // this.addScript();
    sal();
  },
  methods: {
    async preRegister() {
      try {
        this.loading = true;
        this.email = this.email.trim();

        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.invalidEmail = !this.email || !emailRegex.test(this.email);
        if (this.invalidEmail) {
          this.loading = false;
          return;
        }

        this.showSignupModal = true;

        // const result = await this.$apollo.mutate({
        //   mutation: m_pre_register,
        //   variables: {
        //     email: this.email.trim()
        //   }
        // });
        this.loading = false;

        // this.success = result?.data?.pre_register?.success;
        // this.takenEmail = !this.success;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    logout() {
      this.$store.commit("account/logout");
      window.location.href = "/";
    }
  },
  data() {
    return {
      email: "",
      success: false,
      loading: false,
      showLoginModal: false,
      showSignupModal: false,
      invalidEmail: false,
      takenEmail: false,
      mockWidth: null
    };
  },
  components: {
    LoginModal,
    SignupModal,
    Pricing,
    NvokoLogo3d,
    AnimatedText
  },
  computed: {
    user() {
      return this.$store.getters["account/getUser"];
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  background: rgba(0, 0, 0, 0.8);

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15%;
    margin-top: 70px;

    .logo {
      padding-top: 7px;
      padding-bottom: 7.02px;
      padding-left: 7px;
      padding-right: 7px;
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
    }

    .nav-menu {
      padding-left: 40px;
      margin-left: auto;
      justify-content: center;
      align-items: center;
      gap: 35px;
      display: flex;

      .log-in {
        color: white;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        word-wrap: break-word;
        cursor: pointer;
      }

      .log-out {
        color: #ff035a;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        word-wrap: break-word;
        cursor: pointer;
      }

      .sign-up {
        padding: 10px 20px;
        background: #ff035a;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }

  .hero {
    display: grid;
    // grid-template-columns: 35% 55%;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    justify-content: space-between;
    align-items: center;
    width: 100vw;

    // .logo {
    //   width: 100%; /* Adjusted to fill the grid column */
    //   height: 100%;
    //   aspect-ratio: 1;

    //   img {
    //     width: 100%;
    //   }
    // }

    .right {
      display: flex;
      flex-direction: column;

      .title {
        color: white;
        font-size: 5rem;
        font-weight: 600;
        line-height: 80px;
      }

      .description {
        margin-top: 30px;
        color: white;
        font-size: 2rem;
        font-weight: 400;
      }

      .description-light {
        margin-top: 30px;
        color: #ffffff90;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
      }

      .register {
        margin-top: 30px;
        display: flex;
        width: 100%;

        .email-input {
          background: rgba(255, 255, 255, 0.11);
          border-radius: 32.5px;
          border: 1.5px rgba(255, 255, 255, 0.3) solid;
          color: white;
          font-size: 1.2rem;
          font-weight: 700;
          padding: 10px 32px 12px;
        }

        .sign-up {
          background: #ff035a;
          border-radius: 30px;
          border: 1.5px #a60039 solid;
          color: white;
          font-size: 1.2rem;
          font-weight: 700;
          padding: 10px 22px 12px;
          margin-left: 15px;
        }
      }
    }
  }

  .how-does-it-work {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .section-title {
      color: white;
      font-size: 2.1rem;
      font-weight: 700;
      padding: 10px 32px 12px;
      box-shadow: 0px 0px 20px white;
      border-radius: 46px;
      border: 1.5px white solid;
      margin-bottom: 20px;
    }

    .steps {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 100px;

      .step {
        display: flex;
        justify-content: center;

        .step-text {
          width: 35%;

          .title {
            margin-top: 60px;
            color: $white;
            font-size: 42px;
            font-weight: 700;
          }

          .description {
            color: $white;
            font-size: 22px;
            font-weight: 400;
          }
        }

        .step-number {
          border-left: 1px $accent solid;
          margin: 0 60px;

          .number {
            margin-top: 70px;
            font-size: 28px;
            background-color: $accent;
            border-radius: 50%;
            color: $white;
            width: 60px;
            height: 60px;
            padding-bottom: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -30px;
          }
        }

        &:first-child {
          .step-number {
            margin-top: 70px;

            .number {
              margin-top: 0;
            }
          }
        }

        &:last-child {
          .step-number {
            height: fit-content;
          }
        }

        .deal-info {
          width: 35%;
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;

          .party {
            border-radius: 18px;
            background: $white;
            padding: 8px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            width: fit-content;
            height: fit-content;

            .initials {
              height: 32px;
              width: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 2px solid $accent;
              border-radius: 50%;
              color: $accent;
              font-size: 14px;
              font-weight: 700;
              line-height: 10px;
            }

            .party-tag {
              background-color: #000;
              color: $white;
              padding: 0 4px;
              border-radius: 5px;
            }
          }

          .ipi {
            display: flex;
            gap: 8px;
            padding: 6px 10px;
            width: fit-content;
            border-radius: 12px;
            background: $white;
            margin-left: 75px;

            .linked {
              display: flex;
              align-items: center;
              padding: 0 10px 2px;
              border-radius: 10px;
              background: #63be0a;
              color: $white;
              font-size: 14px;
              font-weight: 700;

              .icon {
                margin-right: 6px;
              }
            }
          }

          .summary {
            display: flex;
            gap: 8px;
            border-radius: 18px;
            background: $white;
            padding: 10px 0;
            width: fit-content;
            margin-left: 125px;
            margin-top: 21px;

            .el {
              border-right: 1px solid #9e9e9e;
              padding: 0 15px;

              &:last-child {
                border-right: 0;
              }

              .number {
                color: $accent;
                font-size: 22px;
                font-weight: 700;
              }
            }
          }

          .contribution-host {
            display: flex;
            gap: 24px;
            margin-left: 25px;
            margin-top: 21px;

            .contribution-container {
              border-radius: 18px;
              background: $white;
              padding: 10px 20px;

              .contribution {
                color: $accent;
                font-size: 24px;
                font-weight: 700;
              }
            }
          }

          .collaborator {
            display: flex;

            .party {
              margin-top: 21px;
            }

            .add-collaborator {
              button {
                display: flex;
                align-items: center;
                gap: 10px;
                background: #ff035a;
                border-radius: 30px;
                border: 2px $white solid;
                color: $white;
                font-size: 18px;
                font-weight: 700;
                padding: 4px 32px 6px;
                margin-left: -12px;
              }
            }
          }
        }

        .contract-questions {
          width: 35%;
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;

          .question {
            border-radius: 18px;
            background: $white;
            padding: 18px 25px 20px;
            width: fit-content;

            .title {
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 10px;
            }

            .question-text {
              margin-bottom: 10px;
            }

            .options {
              display: flex;
              gap: 10px;

              .option {
                border-radius: 10px;
                background: #f0f0f0;
                padding: 13px 20px 15px;
                white-space: nowrap;

                &.selected {
                  background-color: $accent;
                  color: $white;
                }
              }
            }
          }

          .status {
            display: flex;
            align-items: center;
            gap: 10px;
            border-radius: 20px;
            padding: 10px 20px 13px;
            color: $white;
            font-size: 20px;
            font-weight: 600;
            width: fit-content;
            height: fit-content;
            white-space: nowrap;

            .icon {
              height: 20px;
              width: 20px;
              display: flex;
              align-items: center;
            }
          }

          .row-1 {
            display: flex;
            margin-left: 75px;

            .status {
              background-color: $accent;
              margin-top: 10px;
              margin-left: -50px;
            }
          }

          .row-2 {
            display: flex;
            margin-top: 65px;
            width: 100%;

            .question-1 {
              min-width: 60%;

              .option {
                width: calc(50% - 5px);
                text-align: center;
              }
            }

            .question-2 {
              height: fit-content;
              display: flex;
              gap: 10px;
              transform: translate(-205px, -40px);

              .status {
                background-color: #03a4ff;
                position: relative;
              }
            }
          }
        }

        .contract-final {
          width: 35%;
          margin-bottom: 60px;

          .top {
            display: flex;
            gap: 20px;
            width: fit-content;

            button {
              display: flex;
              align-items: center;
              gap: 5px;
              background: #03a4ff;
              border-radius: 30px;
              border: 2px $white solid;
              color: $white;
              font-size: 18px;
              font-weight: 700;
              padding: 4px 32px 6px;

              .icon {
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
              }
            }

            .ip {
              display: flex;
              gap: 8px;
              padding: 6px 10px;
              width: fit-content;
              border-radius: 12px;
              background: $white;

              .recorded {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px 2px;
                border-radius: 10px;
                background: #4b4b4b;
                color: $white;
                font-size: 14px;
                font-weight: 700;

                .icon {
                  margin-right: 6px;
                }
              }
            }
          }

          .agreement-container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            width: calc(90% + 20px);

            .agreement {
              background-color: $white;
              border-radius: 20px;
              width: calc(100% - 20px);
              padding: 0 10px;
              overflow: hidden;

              .container {
                position: relative;
                top: 10px;
                display: flex;

                .text {
                  .title {
                    color: #000;
                    font-size: 25px;
                    font-weight: 700;
                  }
                }

                .scroll {
                  width: 50px;
                  margin: 10px 0 30px 20px;
                  border-radius: 9px;
                  background: #777777;

                  .thumb {
                    background: $accent;
                    border-radius: 9px;
                    height: 30px;
                    width: 100%;
                  }
                }
              }
            }

            .accept-sign {
              margin-top: -12px;
              margin-left: auto;
              z-index: 50;

              button {
                display: flex;
                align-items: center;
                gap: 10px;
                background: #ff035a;
                border-radius: 30px;
                border: 2px $white solid;
                color: $white;
                font-size: 18px;
                font-weight: 700;
                padding: 4px 24px 6px;
                margin-left: -12px;
              }
            }

            .signature-container {
              display: flex;
              gap: 10px;
              margin-top: -12px;
              margin-left: 40px;

              .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                border-radius: 20px;
                border: 3px solid $accent;
                background-color: $white;

                img {
                  /* height: 45px; */
                  width: 45px;
                }
              }

              .signature {
                padding: 0 40px;
                border-radius: 30px;
                font-size: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 3px;
                background-color: $white;
                color: #000;
              }
            }
          }
        }
      }
    }
  }

  .join-community {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    .section-title {
      color: $white;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .testimonials {
      width: 80%;
      display: flex;
      gap: 30px;
      margin-top: 70px;

      .testimonial {
        width: calc(33% - 20px);
        color: $white;
        border: 30px solid;
        border-image: url("../assets/testimonial-border.svg");
        border-image-slice: 40;
        border-radius: 10px;
        padding: 0 25px 25px;

        .quotes {
          margin-top: -70px;
        }

        .top {
          display: flex;
          gap: 15px;
          margin-top: 25px;

          .avatar {
            img {
              border-radius: 10px;
            }
          }

          .right {
            .name {
              font-size: 22px;
              font-weight: 600;
            }

            .user-title {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }

        .description {
          margin-top: 10px;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }

    .slider-control {
      display: flex;
      gap: 10px;
      margin-top: 20px;

      .point {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }

  .get-started {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;

    .main,
    .unlimited {
      width: 45%;
      border-radius: 22px;
      border: 2px solid $accent;
      display: flex;
      align-items: center;
    }

    .main {
      flex-direction: column;
      justify-content: center;
      padding: 35px 60px 45px;

      .title {
        color: $white;
        font-size: 34px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .action {
        margin-top: 20px;
      }
    }

    .unlimited {
      margin-top: 20px;
      padding: 15px 30px;

      .action {
        margin-left: auto;
      }
    }

    .description {
      color: $white;
      font-size: 21px;
      font-weight: 400;
      text-align: center;
    }

    .accent {
      color: $accent;
    }

    .action {
      button {
        display: flex;
        align-items: center;
        gap: 10px;
        background: #ff035a;
        border-radius: 30px;
        border: 3px solid #a60039;
        color: $white;
        font-size: 18px;
        font-weight: 700;
        padding: 4px 24px 6px;
        margin-left: -12px;
      }
    }
  }

  .footer {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      img {
        height: 50px;
      }
    }

    .links {
      margin: 30px 0 20px;
      display: flex;
      gap: 30px;

      .link,
      .copyright {
        color: $white;
        font-size: 16px;
        font-weight: 400;
      }

      .link {
        font-weight: 800;
      }

      .socials {
        margin-left: 60px;
        display: flex;
        gap: 20px;

        .icon {
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.error {
  padding-left: 10px;
  color: #a60039;
  font-weight: 600;
}

button {
  cursor: pointer;
}

@media (max-width: 768px) {
  .wrapper {
    .nav {
      padding: 0 5%;
      margin-top: 35px;

      .logo {
        width: 30%;
      }

      .nav-menu {
        .log-in {
          text-align: center;
          cursor: pointer;
        }

        .sign-up {
          text-align: center;
          padding: 6px 12px;
          cursor: pointer;
        }
      }
    }

    .hero {
      flex-direction: column;
      height: auto;
      padding: 40px 5%;

      .logo {
        width: 100%;
        padding: 0 15%;
        text-align: center;

        img {
          max-width: 100px;
          display: inline;
        }
      }

      .right {
        width: 100%;
        margin-top: 20px;

        .title {
          font-size: 3rem;
          line-height: 50px;
        }

        .description {
          font-size: 1.5rem;
        }

        .register {
          flex-direction: column;
          padding: 0 5%;

          .sign-up {
            margin-top: 15px;
            margin-left: 0;
          }
        }
      }
    }

    .how-does-it-work {
      margin-top: 80px;

      .section-title {
        font-size: 1.8rem;
      }

      .steps {
        margin-top: 60px;

        .step {
          flex-direction: column;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          width: 100vw;
          position: relative;
          padding: 0 0 10px 60px;

          .step-number {
            margin: 0 !important;
            position: absolute;
            top: 0;
            left: 40px;
            height: 100%;

            .number {
              width: 40px;
              height: 40px;
              font-size: 22px;
              margin-left: -20px;
              margin-top: 0;
            }
          }

          &:last-child {
            .step-number {
              height: 100%;
            }
          }

          .step-text {
            width: 100%;
            padding: 0 7%;

            .title {
              margin: 0;
              margin-bottom: 15px;
              font-size: 34px;
              line-height: 40px;
            }

            .description {
              font-size: 18px;
            }
          }

          .deal-info {
            transform: scale(0.55);
            width: fit-content;
            margin: -40px;
            margin-left: -20%;
          }

          .contract-questions {
            transform: scale(0.35);
            width: fit-content;
            margin: -20% 0;
            margin-left: -4%;
          }

          .contract-final {
            transform: scale(0.55);
            width: fit-content;
            margin: -50px -50px -100px -20%;
          }

          &:nth-child(2) {
            flex-direction: column;
          }
        }
      }

      .steps-end {
        width: 100%;

        .circle {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: $accent;
          margin-left: 32px;
        }
      }
    }

    .join-community {
      text-align: center;
      overflow-x: hidden;

      .testimonials {
        .testimonial {
          width: 100%;
        }
      }
    }

    .get-started {
      padding: 0 5%;

      .main,
      .unlimited {
        width: 100%;
        flex-direction: column;
        padding: 10px 20px;

        .action {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }

    .footer {
      .links {
        flex-direction: column-reverse;
        align-items: center;

        .socials {
          margin-left: 0;
        }
      }
    }
  }
}

[data-sal] {
  transition-delay: 0s;
  transition-delay: var(--sal-delay, 0s);
  transition-duration: 0.2s;
  transition-duration: var(--sal-duration, 0.2s);
  transition-timing-function: ease;
  transition-timing-function: var(--sal-easing, ease);
}

[data-sal][data-sal-duration="200"] {
  transition-duration: 0.2s;
}

[data-sal][data-sal-duration="250"] {
  transition-duration: 0.25s;
}

[data-sal][data-sal-duration="300"] {
  transition-duration: 0.3s;
}

[data-sal][data-sal-duration="350"] {
  transition-duration: 0.35s;
}

[data-sal][data-sal-duration="400"] {
  transition-duration: 0.4s;
}

[data-sal][data-sal-duration="450"] {
  transition-duration: 0.45s;
}

[data-sal][data-sal-duration="800"] {
  transition-duration: 0.5s;
}

[data-sal][data-sal-duration="550"] {
  transition-duration: 0.55s;
}

[data-sal][data-sal-duration="600"] {
  transition-duration: 0.6s;
}

[data-sal][data-sal-duration="650"] {
  transition-duration: 0.65s;
}

[data-sal][data-sal-duration="700"] {
  transition-duration: 0.7s;
}

[data-sal][data-sal-duration="750"] {
  transition-duration: 0.75s;
}

[data-sal][data-sal-duration="800"] {
  transition-duration: 0.8s;
}

[data-sal][data-sal-duration="850"] {
  transition-duration: 0.85s;
}

[data-sal][data-sal-duration="900"] {
  transition-duration: 0.9s;
}

[data-sal][data-sal-duration="950"] {
  transition-duration: 0.95s;
}

[data-sal][data-sal-duration="1000"] {
  transition-duration: 1s;
}

[data-sal][data-sal-duration="1050"] {
  transition-duration: 1.05s;
}

[data-sal][data-sal-duration="1100"] {
  transition-duration: 1.1s;
}

[data-sal][data-sal-duration="1150"] {
  transition-duration: 1.15s;
}

[data-sal][data-sal-duration="1200"] {
  transition-duration: 1.2s;
}

[data-sal][data-sal-duration="1250"] {
  transition-duration: 1.25s;
}

[data-sal][data-sal-duration="1300"] {
  transition-duration: 1.3s;
}

[data-sal][data-sal-duration="1350"] {
  transition-duration: 1.35s;
}

[data-sal][data-sal-duration="1400"] {
  transition-duration: 1.4s;
}

[data-sal][data-sal-duration="1450"] {
  transition-duration: 1.45s;
}

[data-sal][data-sal-duration="1500"] {
  transition-duration: 1.5s;
}

[data-sal][data-sal-duration="1550"] {
  transition-duration: 1.55s;
}

[data-sal][data-sal-duration="1600"] {
  transition-duration: 1.6s;
}

[data-sal][data-sal-duration="1650"] {
  transition-duration: 1.65s;
}

[data-sal][data-sal-duration="1700"] {
  transition-duration: 1.7s;
}

[data-sal][data-sal-duration="1750"] {
  transition-duration: 1.75s;
}

[data-sal][data-sal-duration="1800"] {
  transition-duration: 1.8s;
}

[data-sal][data-sal-duration="1850"] {
  transition-duration: 1.85s;
}

[data-sal][data-sal-duration="1900"] {
  transition-duration: 1.9s;
}

[data-sal][data-sal-duration="1950"] {
  transition-duration: 1.95s;
}

[data-sal][data-sal-duration="2000"] {
  transition-duration: 2s;
}

[data-sal][data-sal-delay="50"] {
  transition-delay: 0.05s;
}

[data-sal][data-sal-delay="100"] {
  transition-delay: 0.1s;
}

[data-sal][data-sal-delay="150"] {
  transition-delay: 0.15s;
}

[data-sal][data-sal-delay="200"] {
  transition-delay: 0.2s;
}

[data-sal][data-sal-delay="250"] {
  transition-delay: 0.25s;
}

[data-sal][data-sal-delay="300"] {
  transition-delay: 0.3s;
}

[data-sal][data-sal-delay="350"] {
  transition-delay: 0.35s;
}

[data-sal][data-sal-delay="400"] {
  transition-delay: 0.4s;
}

[data-sal][data-sal-delay="450"] {
  transition-delay: 0.45s;
}

[data-sal][data-sal-delay="500"] {
  transition-delay: 0.5s;
}

[data-sal][data-sal-delay="550"] {
  transition-delay: 0.55s;
}

[data-sal][data-sal-delay="600"] {
  transition-delay: 0.6s;
}

[data-sal][data-sal-delay="650"] {
  transition-delay: 0.65s;
}

[data-sal][data-sal-delay="700"] {
  transition-delay: 0.7s;
}

[data-sal][data-sal-delay="750"] {
  transition-delay: 0.75s;
}

[data-sal][data-sal-delay="800"] {
  transition-delay: 0.8s;
}

[data-sal][data-sal-delay="850"] {
  transition-delay: 0.85s;
}

[data-sal][data-sal-delay="900"] {
  transition-delay: 0.9s;
}

[data-sal][data-sal-delay="950"] {
  transition-delay: 0.95s;
}

[data-sal][data-sal-delay="1000"] {
  transition-delay: 1s;
}

[data-sal][data-sal-easing="linear"] {
  transition-timing-function: linear;
}

[data-sal][data-sal-easing="ease"] {
  transition-timing-function: ease;
}

[data-sal][data-sal-easing="ease-in"] {
  transition-timing-function: ease-in;
}

[data-sal][data-sal-easing="ease-out"] {
  transition-timing-function: ease-out;
}

[data-sal][data-sal-easing="ease-in-out"] {
  transition-timing-function: ease-in-out;
}

[data-sal][data-sal-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

[data-sal][data-sal-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[data-sal][data-sal-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

[data-sal][data-sal-easing="ease-in-circ"] {
  transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

[data-sal][data-sal-easing="ease-out-circ"] {
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

[data-sal][data-sal-easing="ease-in-out-circ"] {
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

[data-sal][data-sal-easing="ease-in-expo"] {
  transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

[data-sal][data-sal-easing="ease-out-expo"] {
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

[data-sal][data-sal-easing="ease-in-out-expo"] {
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

[data-sal][data-sal-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-sal][data-sal-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-sal][data-sal-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-sal][data-sal-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

[data-sal][data-sal-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

[data-sal][data-sal-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}

[data-sal][data-sal-easing="ease-in-quint"] {
  transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

[data-sal][data-sal-easing="ease-out-quint"] {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

[data-sal][data-sal-easing="ease-in-out-quint"] {
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

[data-sal][data-sal-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-sal][data-sal-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-sal][data-sal-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-sal][data-sal-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-sal][data-sal-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-sal][data-sal-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-sal|="fade"] {
  opacity: 0;
  transition-property: opacity;
}

[data-sal|="fade"].sal-animate,
body.sal-disabled [data-sal|="fade"] {
  opacity: 1;
}

[data-sal|="slide"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal="slide-up"] {
  transform: translateY(20%);
}

[data-sal="slide-down"] {
  transform: translateY(-20%);
}

[data-sal="slide-left"] {
  transform: translateX(20%);
}

[data-sal="slide-right"] {
  transform: translateX(-20%);
}

[data-sal|="slide"].sal-animate,
body.sal-disabled [data-sal|="slide"] {
  opacity: 1;
  transform: none;
}

[data-sal|="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal="zoom-in"] {
  transform: scale(0.5);
}

[data-sal="zoom-out"] {
  transform: scale(1.1);
}

[data-sal|="zoom"].sal-animate,
body.sal-disabled [data-sal|="zoom"] {
  opacity: 1;
  transform: none;
}

[data-sal|="flip"] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: transform;
}

[data-sal="flip-left"] {
  transform: perspective(2000px) rotateY(-91deg);
}

[data-sal="flip-right"] {
  transform: perspective(2000px) rotateY(91deg);
}

[data-sal="flip-up"] {
  transform: perspective(2000px) rotateX(-91deg);
}

[data-sal="flip-down"] {
  transform: perspective(2000px) rotateX(91deg);
}

[data-sal|="flip"].sal-animate,
body.sal-disabled [data-sal|="flip"] {
  transform: none;
}
</style>
