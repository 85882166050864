<template>
  <div class="flex items-center justify-center z-50">
    <div class="relative inline-block text-left">
      <slot
        name="button"
        :toggleDropdown="toggleDropdown"
        :isDropdownOpen="isDropdownOpen"
      >
      </slot>
      <div
        v-show="isDropdownOpen"
        class="z-50 absolute left-0 top-full mt-2 ml-10 w-56 rounded-lg ring-1 ring-white ring-opacity-5"
        style="box-shadow: 0px 0px 10px 0px #80808033; background-color: #181818;"
      >
        <div
          class="py-2 p-2"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdown-button"
        >
          <slot
            name="items"
            :toggleDropdown="toggleDropdown"
            :isDropdownOpen="isDropdownOpen"
          >
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  data() {
    return {
      isDropdownOpen: false
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>
