<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <template v-if="page === 1">
          <div class="modal-container page-1">
            <SignupHeader :hideLogo="true" @close="$emit('close')" />

            <div class="modal-body">
              <slot name="body">
                <div class="logo">
                  <img src="../../assets/logo.png" alt="Nvoko Logo" />
                </div>
                <h2>Nice to meet you!</h2>
                <h3>
                  Let's get to know each other.
                </h3>
                <div class="inputs">
                  <div
                    class=" flex flex-row items-center justify-between gap-1.5 w-full pb-4"
                  >
                    <input
                      v-model="firstName"
                      id="name"
                      type="text"
                      class="text-input dark small-text"
                      placeholder="Enter your first name"
                    />
                    <input
                      v-model="lastName"
                      id="name"
                      type="text"
                      class="text-sm text-input dark small-text"
                      placeholder="Enter your last name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      v-model="email"
                      id="email"
                      type="email"
                      class="text-input dark"
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      v-model="password"
                      id="password"
                      type="password"
                      class="text-input dark"
                      placeholder="Type the password"
                    />
                  </div>

                  <ul class="unstyled centered">
                    <li>
                      <input
                        v-model="acceptedTerms"
                        class="styled-checkbox"
                        id="styled-checkbox-1"
                        type="checkbox"
                        value="true"
                      />
                      <label for="styled-checkbox-1">
                        <span>
                          I agree with Nvoko's
                          <span
                            @click.stop
                            style="color: #ff035a"
                            class="inline-flex text-[#ff035a]"
                          >
                            <a
                              :href="termsUrl"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              terms and conditions
                            </a>
                          </span>
                          and I’m over the age of 18.
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
                <button
                  @click="onSignUpClick()"
                  class="primary"
                  :disabled="!enableSignupButton || isLoading"
                >
                  {{ signupButtonText }}
                </button>
                <span class="mt-2 text-sm text-white">
                  Already have an account?

                  <span
                    class="cursor-pointer"
                    style="color: #ff035a"
                    @click="$emit('close', 'login')"
                  >
                    Login
                  </span>
                  instead.
                </span>
              </slot>
            </div>
          </div>
        </template>

        <template v-if="page === 2">
          <div class="modal-container page-2">
            <SignupHeader :progress="progressPercent" @close="$emit('close')" />

            <div class="modal-body">
              <slot name="body">
                <div class="step-1">
                  <h2>Tell us about your music.</h2>
                  <h3>
                    Search for your Artist's Spotify profile.
                  </h3>
                  <div class="search">
                    <div class="icon">
                      <SearchIcon :dark="true" />
                    </div>
                    <input
                      v-model.lazy="spotifySearchQuery"
                      type="search"
                      name="search"
                      id="search"
                      placeholder="Search by artist name or paste a Spotify URL"
                      autocomplete="off"
                    />
                  </div>
                  <div v-if="spotifySearchQuery" class="search-results">
                    <div
                      @scroll="handleSpotifySearchScroll($event)"
                      class="wrapper"
                    >
                      <div
                        class="result"
                        :class="{ selected: artist.id === spotifyId }"
                        v-for="artist in spotifySearchResults"
                        :key="artist.id"
                        @click="selectSpotifyArtist(artist)"
                      >
                        <div class="avatar">
                          <img :src="artist.image" />
                        </div>
                        <span class="name">{{ artist.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="!spotifySearchQuery" class="links">
                    <a href="">Can't find yourself?</a>
                    <a href="">Manager or Label?</a>
                  </div>
                </div>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <div class="buttons">
                  <!--                  <button-->
                  <!--                    @click="onStep1BackClick()"-->
                  <!--                    class="primary outline dark"-->
                  <!--                  >-->
                  <!--                    Back-->
                  <!--                  </button>-->
                  <button
                    @click="onStep1NextClick()"
                    :disabled="!enableStep1NextButton || isLoading"
                    class="primary"
                  >
                    {{ nextStepButtonText }}
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </template>

        <template v-if="page === 3">
          <div class="modal-container page-3">
            <SignupHeader @close="$emit('close')" />

            <div class="modal-body">
              <slot name="body">
                <div class="claimed">
                  <div class="avatar">
                    <img :src="spotifyImage" />
                  </div>
                  <h2>This profile is already claimed</h2>
                  <div class="buttons">
                    <button
                      @click="onSpotifyClaimedBackClick()"
                      class="primary outline dark"
                    >
                      Back
                    </button>
                    <button
                      @click="onSpotifyClaimedMistakeClick()"
                      class="primary"
                    >
                      This is a mistake
                    </button>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </template>

        <template v-if="page === 4">
          <div class="modal-container page-4">
            <SignupHeader :progress="progressPercent" @close="$emit('close')" />

            <div class="modal-body">
              <slot name="body">
                <div class="step-2">
                  <h2 class="title">
                    What's your role?
                  </h2>
                  <div class="radiobuttons dark">
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="role"
                          type="radio"
                          id="providing_1"
                          name="providing"
                          value="artist"
                        />
                        <span class="text">Artist/Producer</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="role"
                          type="radio"
                          id="providing_2"
                          name="providing"
                          value="manager"
                        />
                        <span class="text">Manager</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="role"
                          type="radio"
                          id="providing_3"
                          name="providing"
                          value="label"
                        />
                        <span class="text">Label</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="role"
                          type="radio"
                          id="providing_4"
                          name="providing"
                          value="legal"
                        />
                        <span class="text">Legal</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="radio-group">
                      <label class="radio">
                        <input
                          v-model="role"
                          type="radio"
                          id="providing_5"
                          name="providing"
                          value="other"
                        />
                        <span class="text">Other</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <div class="buttons">
                  <button
                    @click="onStep2BackClick()"
                    class="primary outline dark"
                  >
                    Back
                  </button>
                  <button
                    @click="onStep2NextClick()"
                    :disabled="!enableStep2NextButton || isLoading"
                    class="primary"
                  >
                    {{ nextStepButtonText }}
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </template>

        <template v-if="page === 5">
          <div class="modal-container page-5">
            <SignupHeader :progress="progressPercent" @close="$emit('close')" />

            <div class="modal-body">
              <slot name="body">
                <div class="step-3">
                  <h2 class="title">
                    You are open to providing...
                  </h2>
                  <div class="checkboxbuttons dark">
                    <div class="checkbox-group">
                      <label class="checkbox">
                        <input
                          v-model="openTo"
                          type="checkbox"
                          id="providing1"
                          name="providing"
                          value="feedback"
                        />
                        <span class="text">Feedback</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="checkbox-group">
                      <label class="checkbox">
                        <input
                          v-model="openTo"
                          type="checkbox"
                          id="providing2"
                          name="providing"
                          value="features"
                        />
                        <span class="text">Features</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="checkbox-group">
                      <label class="checkbox">
                        <input
                          v-model="openTo"
                          type="checkbox"
                          id="providing3"
                          name="providing"
                          value="production"
                        />
                        <span class="text">Production</span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <span class="hint">Hint: You can select more than one.</span>
                </div>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <div class="buttons">
                  <button
                    @click="onStep3BackClick()"
                    class="primary outline dark"
                  >
                    Back
                  </button>
                  <button
                    @click="onStep3NextClick()"
                    :disabled="!enableStep3NextButton || isLoading"
                    class="primary"
                  >
                    {{ nextStepButtonText }}
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </template>

        <template v-if="page === 6">
          <div class="modal-container page-6">
            <SignupHeader :progress="progressPercent" @close="$emit('close')" />

            <div class="modal-body">
              <slot name="body">
                <div class="step-4">
                  <h2>Connect your socials</h2>
                  <h3>
                    To help verify you, please connect the social media accounts
                    related to this artist.
                  </h3>
                  <div class="accounts">
                    <div
                      @click="toggleSocial('instagram', 'asd')"
                      :class="{ active: instagram }"
                      class="account"
                    >
                      <div class="icon">
                        <img
                          src="../../assets/icons/Social-round-instagram.svg"
                        />
                      </div>
                      <div class="name">
                        <span>Instagram</span>
                      </div>
                      <div v-if="instagram" class="check">
                        <img src="../../assets/icons/Check.svg" />
                      </div>
                    </div>
                    <div
                      @click="toggleSocial('facebook', 'asd')"
                      :class="{ active: facebook }"
                      class="account"
                    >
                      <div class="icon">
                        <img
                          src="../../assets/icons/Social-round-facebook.svg"
                        />
                      </div>
                      <div class="name">
                        <span>Facebook</span>
                      </div>
                      <div v-if="facebook" class="check">
                        <img src="../../assets/icons/Check.svg" />
                      </div>
                    </div>
                    <div
                      @click="toggleSocial('twitter', 'asd')"
                      :class="{ active: twitter }"
                      class="account"
                    >
                      <div class="icon">
                        <img
                          src="../../assets/icons/Social-round-twitter.svg"
                        />
                      </div>
                      <div class="name">
                        <span>Twitter</span>
                      </div>
                      <div v-if="twitter" class="check">
                        <img src="../../assets/icons/Check.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <div class="buttons">
                  <button
                    @click="onStep4BackClick()"
                    class="primary outline dark"
                  >
                    Back
                  </button>
                  <button
                    @click="onStep4FinishClick()"
                    :disabled="!enableStep4FinishButton || isLoading"
                    class="primary"
                  >
                    {{ finishButtonText }}
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import gql from "graphql-tag";
import SignupHeader from "@/components/modals/SignupHeader";
import SearchIcon from "@/assets/icons/Search.vue";
import GET_DEFAULT_ARTIST from "@/api/queries/GET_DEFAULT_ARTIST.gql";

const REGISTER = gql`
  mutation register(
    $email: String!
    $first_name: String!
    $last_name: String!
    $password: String!
  ) {
    register(
      email: $email
      first_name: $first_name
      last_name: $last_name
      password: $password
    ) {
      token
      user {
        email
        first_name
        last_name
        avatar_url
        id
        status
        onboarding_complete
        charges_enabled
        payouts_enabled
        is_waitlisted
      }
    }
  }
`;
const SPOTIFY_SEARCH_ARTISTS = gql`
  query spotify_search_artists($limit: Int!, $offset: Int!, $query: String!) {
    spotify_search_artists(limit: $limit, offset: $offset, query: $query) {
      id
      image
      name
      url
    }
  }
`;
const ARTISTS = gql`
  query artists($spotify: String!) {
    artists(where: { spotify_id: { _eq: $spotify } }) {
      id
    }
  }
`;
const ADD_ARTIST = gql`
  mutation add_artist($spotify: String!, $role: String!) {
    add_artist(artist_spotify_id: $spotify, role: $role) {
      id
    }
  }
`;
const INSERT_ARTIST_PREFERENCES = gql`
  mutation insert_artist_preferences_one(
    $artist_id: uuid
    $feedback: Boolean
    $features: Boolean
    $production: Boolean
  ) {
    insert_artist_preferences_one(
      object: {
        artist_id: $artist_id
        provides_feedback: $feedback
        provides_features: $features
        provides_production: $production
      }
    ) {
      id
      provides_features
      provides_feedback
      provides_production
    }
  }
`;
const ADD_ARTIST_INSTAGRAM = gql`
  mutation add_artist_instagram($artist_id: String!, $code: String!) {
    add_artist_instagram(artist_id: $artist_id, code: $code) {
      success
    }
  }
`;
export default {
  name: "SignupModal",
  components: {
    SearchIcon,
    SignupHeader
  },
  props: {
    initialEmail: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      page: 1,
      pages: 6,
      step: 1,
      steps: 4,
      search: null,
      fullName: null,
      firstName: null,
      lastName: null,
      email: this.initialEmail,
      password: null,
      acceptedTerms: false,
      spotifySearchLimit: 10,
      spotifySearchOffset: 0,
      spotifySearchQuery: null,
      spotifySearchResults: [],
      spotifyId: null,
      spotifyImage: null,
      claimedArtistId: null,
      role: null,
      openTo: [],
      instagram: null,
      facebook: null,
      twitter: null,
      artistId: null,
      isLoading: false,
      isPreRegister: true
    };
  },
  computed: {
    progressPercent() {
      return (this.step / this.steps) * 100;
    },
    user() {
      return this.$store.getters["account/getUser"];
    },
    enableSignupButton() {
      return (
        this.firstName &&
        this.lastName &&
        this.email &&
        this.password &&
        this.acceptedTerms
      );
    },
    enableStep1NextButton() {
      return this.spotifyId;
    },
    enableStep2NextButton() {
      return this.role;
    },
    enableStep3NextButton() {
      return this.openTo.length;
    },
    enableStep4FinishButton() {
      return this.instagram || this.facebook || this.twitter;
    },
    signupButtonText() {
      return this.isLoading ? "Loading..." : "Sign up";
    },
    nextStepButtonText() {
      return this.isLoading ? "Loading..." : "Next";
    },
    finishButtonText() {
      return this.isLoading ? "Loading..." : "Finish";
    },
    termsUrl() {
      return this.$router.resolve({ name: "TermsOfUse" }).href;
    }
  },
  watch: {
    spotifySearchQuery: async function(query) {
      this.spotifySearchOffset = 0;
      this.spotifySearchResults = [];
      if (query) {
        await this.searchSpotify();
      }
    }
  },
  methods: {
    goToPage(page) {
      this.page = page;
    },
    goToPrevPage() {
      if (this.page > 1) {
        this.page--;
      }
    },
    goToNextPage() {
      if (this.page < this.pages) {
        this.page++;
      }
    },
    toggleSocial(social, value) {
      if (this[social]) {
        this[social] = null;
      } else {
        this[social] = value;
      }
    },
    selectSpotifyArtist(artist) {
      this.spotifyId = artist.id;
      this.spotifyImage = artist.image;
    },
    async handleSpotifySearchScroll(event) {
      const { offsetHeight, scrollTop, scrollHeight } = event.target;
      if (offsetHeight + scrollTop >= scrollHeight) {
        console.log("hasScrolledToBottom");
        this.spotifySearchOffset = this.spotifySearchOffset + 10;
        await this.searchSpotify();
      }
    },
    async onSignUpClick() {
      try {
        this.isLoading = true;
        await this.register();
        this.goToPage(2);
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.isLoading = false;
      }
    },
    // spotify search page
    async onStep1BackClick() {
      this.goToPage(1);
    },
    async onStep1NextClick() {
      try {
        this.isLoading = true;
        const claimed = await this.checkIfSpotifyClaimed();
        if (claimed) {
          this.goToPage(3);
          this.step = 2;
        } else {
          this.goToPage(4);
          this.step = 2;
        }
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.isLoading = false;
      }
    },
    // Spotify profile claimed page
    async onSpotifyClaimedBackClick() {
      this.goToPage(2);
    },
    async onSpotifyClaimedMistakeClick() {
      this.goToPage(2);
    },
    // what's your role page
    async onStep2BackClick() {
      this.goToPage(2);
      this.step = 1;
    },
    async onStep2NextClick() {
      try {
        this.isLoading = true;
        await this.addArtist();
        this.goToPage(5);
        this.step = 3;
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.isLoading = false;
      }
    },
    // You are open to providing page
    async onStep3BackClick() {
      this.goToPage(4);
      this.step = 2;
    },
    async onStep3NextClick() {
      try {
        this.isLoading = true;
        await this.updateArtistPreferences();
        this.goToPage(6);
        this.step = 4;
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.isLoading = false;
      }
    },
    // Connect socials page
    async onStep4BackClick() {
      this.goToPage(5);
      this.step = 3;
    },
    async onStep4FinishClick() {
      try {
        this.isLoading = true;
        await this.addArtistInstagram();
      } catch (e) {
        alert(e.message || e);
      } finally {
        this.isLoading = false;
      }
    },
    async register() {
      const result = await this.$apollo.mutate({
        mutation: REGISTER,
        variables: {
          email: this.email.trim(),
          // full_name: this.fullName.trim(),
          first_name: this.firstName.trim(),
          last_name: this.lastName.trim(),
          password: this.password.trim()
        }
      });
      const { register } = result.data;
      this.$token = register.token;
      this.$store.commit("account/authSuccess", register);

      const {
        data: { artists }
      } = await this.$apollo.query({
        query: GET_DEFAULT_ARTIST,

        variables: {
          userId: this.user.id
        }
      });

      this.$store.commit("account/updateArtist", artists[0]);
      if (this.user.status === "unverified")
        this.$router.push({ name: "SignupVerification" });
      else if (!this.user.onboarding_complete)
        this.$router.push({ name: "Onboarding" });
      else this.$router.push({ name: "Catalog" });
    },
    async searchSpotify() {
      const result = await this.$apollo.query({
        query: SPOTIFY_SEARCH_ARTISTS,
        variables: {
          limit: this.spotifySearchLimit,
          offset: this.spotifySearchOffset,
          query: this.spotifySearchQuery
        }
      });
      const { spotify_search_artists } = result.data;
      this.spotifySearchResults = [
        ...this.spotifySearchResults,
        ...spotify_search_artists
      ];
    },
    async checkIfSpotifyClaimed() {
      const result = await this.$apollo.query({
        query: ARTISTS,
        variables: {
          spotify: this.spotifyId
        }
      });
      const { artists } = result.data;
      const artist = artists[0];
      const claimed = artists.length;
      if (claimed) {
        this.claimedArtistId = artist.id;
      }
      return claimed;
    },
    async addArtist() {
      const result = await this.$apollo.mutate({
        mutation: ADD_ARTIST,
        variables: {
          spotify: this.spotifyId,
          role: this.role
        }
      });
      const { add_artist } = result.data;
      const { id } = add_artist;
      this.artistId = id;
    },
    async updateArtistPreferences() {
      await this.$apollo.mutate({
        mutation: INSERT_ARTIST_PREFERENCES,
        variables: {
          artist_id: this.artistId,
          feedback: this.openTo.includes("feedback"),
          features: this.openTo.includes("features"),
          production: this.openTo.includes("production")
        }
      });
    },
    async addArtistInstagram() {
      const result = await this.$apollo.mutate({
        mutation: ADD_ARTIST_INSTAGRAM,
        variables: {
          artist_id: this.artistId,
          code: "asd"
        }
      });
      console.log(result);
    }
  },
  mounted() {
    this.email = this.initialEmail;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../styles/_mixins.scss";

.modal-container {
  &.page-2,
  &.page-4,
  &.page-5,
  &.page-6 {
    display: flex;
    flex-direction: column;
  }
}

.page-1 {
  .modal-body {
    padding: 0 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.page-2,
.page-3,
.page-4,
.page-5,
.page-6 {
  .modal-body {
    flex: 1;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.page-1 {
  .logo {
    margin-bottom: 32px;
  }

  h2 {
    color: $white;
    margin-bottom: 16px;
  }

  h3 {
    color: $white;
    font-weight: 300;
    margin-bottom: 32px;
  }

  .inputs {
    width: 100%;
    margin-bottom: 32px;

    .form-group {
      margin-bottom: 24px;

      input {
        @include details-light();
        color: $white;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.22px;
        color: #fcfffc;
      }
    }
  }

  button {
    width: 136px;
    height: 44px;
  }
}

.page-2 {
  .step-1 {
    width: 100%;
    padding: 0 180px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      color: $white;
      margin-bottom: 16px;
      text-align: center;
    }

    h3 {
      color: $white;
      font-weight: 300;
      margin-bottom: 32px;
      text-align: center;
    }

    .search {
      flex: 1;
      display: flex;
      align-items: center;
      width: 321px;
      /* background: transparent; */
      justify-content: center;
      margin-bottom: 16px;

      .icon {
        width: 20px;
        display: flex;
      }

      input {
        background: transparent;
        font-weight: 300;
        font-size: 14px;
        line-height: 15px;
        display: flex;
        align-items: center;
        letter-spacing: -0.22px;
        color: $white;

        &::placeholder {
          font-weight: 300;
          font-size: 14px;
          line-height: 24px;
          color: $white;
        }
      }
    }

    .search-results {
      width: 305px;
      height: 180px;
      background: $text;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      padding: 16px 16px 16px 0;

      .wrapper {
        overflow-y: scroll;
        width: 100%;
        scrollbar-width: thin;
        scrollbar-color: #adadad rgba(#adadad, 0.3);

        &::-webkit-scrollbar {
          /* width of the entire scrollbar */
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          /* color of the tracking area */
          @include background-opacity(#adadad, 0.3);
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          /* color of the scroll thumb */
          background-color: #adadad;
          /* roundness of the scroll thumb */
          border-radius: 5px;
        }

        .result {
          display: flex;
          align-items: center;
          padding: 4px 4px 4px 20px;

          &:hover,
          &.selected {
            @include background-opacity($accent, 0.1);
          }

          .avatar {
            width: 30px;
            height: 30px;

            img {
              border-radius: 50%;
            }
          }

          .name {
            @include category-medium();
            color: $white;
            font-weight: bold;
            margin: 0 8px;
          }

          .info {
            @include details-light();
            color: $white;
          }
        }
      }
    }

    .links {
      a {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.22px;
        text-decoration-line: underline;
        color: $accent;
        margin: 0 15px;
      }
    }
  }
}

.page-3 {
  .claimed {
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar {
      width: 120px;
      height: 120px;

      img {
        border-radius: 50%;
      }
    }

    h2 {
      color: $white;
      margin-top: 40px;
      margin-bottom: 32px;
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: center;
      width: 100%;

      button {
        width: 136px;
        height: 44px;
        margin: 0 16px;
        font-weight: 300;
        padding: 0 8px;
      }
    }
  }
}

.page-4 {
  .step-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 99px;

    h2 {
      color: $white;
      margin: 0 0 24px;
      text-align: center;
    }

    .radiobuttons {
      margin-bottom: 16px;
    }
  }
}

.page-5 {
  .step-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 99px;

    h2 {
      color: $white;
      margin: 0 0 24px;
      text-align: center;
    }

    .checkboxbuttons {
      margin-bottom: 16px;

      .checkbox-group {
        margin-bottom: 0;
      }
    }

    .hint {
      @include details-light();
      color: $white;
      font-weight: 300;
    }
  }
}

.page-6 {
  .step-4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 170px;

    h2 {
      color: $white;
      margin-bottom: 16px;
      text-align: center;
    }

    h3 {
      color: $white;
      font-weight: 300;
      margin-bottom: 32px;
      text-align: center;
    }

    .accounts {
      width: 370px;

      .account {
        display: flex;
        padding: 8px 16px;
        background: $text-additional;
        border-radius: 25px;
        margin-bottom: 32px;

        &.active {
          background: $white;

          .name {
            span {
              color: $text;
            }
          }
        }

        .icon {
          width: 30px;
          height: 30px;

          img {
            object-fit: cover;
          }
        }

        .name {
          flex: 1;
          margin-left: 16px;

          span {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.22px;
            color: $white;
            font-weight: 300;
          }
        }

        .check {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #7cc576;
          overflow: hidden;
          border-radius: 50%;

          img {
            object-fit: cover;
          }
        }
      }
    }
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;

  button {
    width: 136px;
    height: 44px;
    margin: 0 16px;
    font-weight: 300;
  }
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    display: flex;
    position: relative;
    cursor: pointer;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.22px;
    color: #fcfffc;
  }

  // Box.
  & + label:before {
    content: "";
    margin-top: 2px;
    margin-right: 8px;
    display: inline-block;
    vertical-align: text-top;
    width: 14px;
    height: 14px;
    background: transparent;
    border: 1px solid white;
  }

  // Box hover
  // &:hover + label:before {
  //   background: #f35429;
  // }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: transparent;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 6px;

    width: 2px;
    height: 2px;
    margin-top: 2px;
    background: white;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
